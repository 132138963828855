/*!
 * mobileNavigation.js
 * Created by Santiago Prada
 * Copyright © 2016 IBM. All rights reserved.
 */
$(function() {
    $('#mobile-btn').click(function() {
        $('#mobile-navigation').toggleClass('open');
    });
});


var MobileNavigation = function(parentView, applicationProperties) {

    var that = this;

    //Init Controller fuction
    this.init = function() {

        //Component Properties
        this.parentView = parentView
        this.container = $("#mobile-nav", this.parentView);

        //Add Search Component to mobile navigation
        var mobileSearchComponent = new Search($("#mobile-search", that.parentView), applicationProperties);

        //Collapsible Menus

        this.containers = $(".mobile-menu-container");
        this.subMenus = $(".mobile-sub-menu");
        this.primaryLinks = $(".primary-link");

        //Hide All subMenus
        this.subMenus.hide();

        $.each(that.containers, function(index, container){

          var subMenu = $(".mobile-sub-menu" , container);


            //Only assign events to containers with sub Menus
            var primaryLink = $(".primary-link", container);

            primaryLink.on("click", function(e){
              if( subMenu.length != 0 ) {
                e.preventDefault();
              }

              if ( primaryLink.hasClass("active-primary-link") ) {
                that.subMenus.slideUp();
                that.primaryLinks.removeClass("active-primary-link");
                return
              }else {
                that.primaryLinks.removeClass("active-primary-link");
                primaryLink.addClass("active-primary-link");
                that.subMenus.slideUp();
                subMenu.slideDown();
              };
            });
          });
    };


    this.init();
};
